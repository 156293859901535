import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithEmailAndPassword,
    UserCredential,
    browserSessionPersistence,
    sendPasswordResetEmail,
    signOut,
    setPersistence,
} from 'firebase/auth';
const firebaseConfig = {
    apiKey: 'AIzaSyBWery0TdPRKVyNtZBW1x6P9_as0olnX2w',
    authDomain: 'ehealth-v2.firebaseapp.com',
    databaseURL: 'https://ehealth-v2-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'ehealth-v2',
    storageBucket: 'ehealth-v2.appspot.com',
    messagingSenderId: '1092247891639',
    appId: '1:1092247891639:web:bd00051630671a8829a732',
    measurementId: 'G-RH0F4VJCGG',
};

export const organization = 'carelabs.se';

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);

export const signInWithEmailPassword = async (
    email: string,
    password: string
): Promise<UserCredential> => {
    await setPersistence(authentication, browserSessionPersistence);
    return signInWithEmailAndPassword(authentication, email, password);
};

export const sendResetEmail = async (email: string): Promise<void> => {
    await sendPasswordResetEmail(authentication, email);
};

export const signOutUser = (): Promise<void> => {
    return signOut(authentication);
};
