import React, { FC, useEffect, useState } from 'react';
import { organization, signInWithEmailPassword } from 'firebase-service';
import { FirebaseError } from 'firebase/app';
import { useNavigate } from 'react-router-dom';
// import logo from '../../images/carelabs-logo.png';

const Login: FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const onEnterSignIn = async (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                await signIn();
            }
        };
        window.addEventListener('keydown', onEnterSignIn);
        return () => window.removeEventListener('keydown', onEnterSignIn);
    });

    const validateEmail = (email: string) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const signIn = async () => {
        const userEmail = `${username}@${organization}`;
        if (!validateEmail(userEmail)) {
            return;
        }

        try {
            await signInWithEmailPassword(userEmail, password);
            navigate('/');
            // eslint-disable-next-line no-empty
        } catch (error) {
            if (error instanceof FirebaseError) {
                if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
                    setErrorMessage('Felaktigt användarnamn eller lösenord');
                    return;
                }
                return;
            }
            setErrorMessage('Oups! Något gick fel!');
        }
    };

    useEffect(() => {
        setErrorMessage('');
        setConfirmationMessage('');
        return;
    }, [username, password]);
    return (
        <div className="flex flex-col space-y-6 py-8 px-16 shadow-lg rounded-lg bg-gray-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 items-center justify-center w-max">
            <div className="flex justify-center items-center gap-3">
                {/* <img src={logo} className="w-10" alt="" /> */}
                <h2 className="text-xl font-semibold pt-1"> Carelabs Admin Panel</h2>
            </div>
            <div className="h-5">
                {errorMessage && !confirmationMessage && (
                    <p className="text-red-600">{errorMessage}</p>
                )}
                {confirmationMessage && !errorMessage && (
                    <p className="text-green-600">{confirmationMessage}</p>
                )}
            </div>
            <div className="grid gap-6">
                <div className="flex flex-col gap-2">
                    <p className="text-sm">Användarnamn</p>
                    <input
                        className="p-2 w-60 rounded border border-hs-blue"
                        onChange={(e) => setUsername(e.currentTarget.value)}
                        value={username}
                        type="text"
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-sm">Lösenord</p>
                    <input
                        className="p-2 w-60 rounded border border-hs-blue"
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        value={password}
                        type="password"
                    />
                </div>
            </div>
            <button
                onClick={async () => await signIn()}
                className="px-4 py-2 rounded border border-hs-blue bg-hs-blue text-white hover:bg-white hover:text-hs-blue transition duration-200"
            >
                Logga in
            </button>
        </div>
    );
};

export default Login;
