/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, FormEvent, useState } from 'react';
import Switch from 'react-switch';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input } from 'components/Input';
import { Toggle } from 'components/Toggle';
import { Wrapper } from 'features/Wrapper';

const NewClinic = () => {
    const [state, setState] = useState<any>({
        clinicID: '',
        careLabsBookingIntegration: false,
        hasPaymentsIntegration: false,
        klarnaId: '',
        klarnaPassword: '',
        swishNumber: '',
        webDocClientId: '',
        webDocClientSecret: '',
        webDocClinicId: '',
        paymentMethods: [],
        config: {
            clinicName: '',
            acceptPayment: false,
            email: '',
            isChildrenClinic: false,
            lateArrivalMargin: 5,
            logo: '',
            screening: false,
            showUpcomingBookings: false,
            upcomingBookingsPeriod: 0,
            survey: false,
            timer: 30,
        },
        webDocIntegration: false,
    });

    const notify = (status: string) => {
        if (status === 'success') {
            toast.success('Clinic added successfully', {
                autoClose: 1000,
            });
        }
        if (status === 'error') {
            toast.error('Something went wrong', {
                autoClose: 1000,
            });
        }
    };

    const handleConfigToggle = (id: string) => {
        setState({ ...state, config: { ...state.config, [id]: !state.config[id] } });
    };
    const handleToggle = (id: string) => {
        setState({ ...state, [id]: !state[id] });
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const updateConfig = (e: ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, config: { ...state.config, [e.target.name]: e.target.value } });
    };
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !state.clinicID) return;
        const storage = getStorage();
        const storageRef = ref(storage, `images/${state.clinicID}/${e.target.files[0].name}`);
        const snapshot = await uploadBytes(storageRef, e.target.files[0]);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setState({ ...state, config: { ...state.config, logo: downloadURL } });
    };
    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        const db = getFirestore();
        setDoc(doc(db, 'clinicSettings', state.clinicID), state)
            .then(() => {
                return notify('success');
            })
            .catch(() => notify('error'));
    };

    return (
        <Wrapper title="Ny klinik">
            <ToastContainer />
            <div className="p-8 mt-14">
                <form onSubmit={handleFormSubmit} className="flex flex-col items-center gap-y-12">
                    <div className="grid grid-cols-2 gap-y-8 gap-x-16">
                        <Input
                            label="Klinik ID:"
                            name="clinicID"
                            value={state.clinicID}
                            handleInputChange={handleInputChange}
                            type="text"
                            required
                        />
                        <Toggle
                            label="Betalningsintegration"
                            name="hasPaymentsIntegration"
                            value={state.hasPaymentsIntegration}
                            handleToggleChange={handleToggle}
                        />
                        <Input
                            label="Klarna Id"
                            name="klarnaId"
                            value={state.klarnaId}
                            handleInputChange={handleInputChange}
                            type="text"
                        />

                        {state?.hasPaymentsIntegration ? (
                            <Toggle
                                label="Betalningar"
                                name="acceptPayment"
                                value={state.config.acceptPayment}
                                handleToggleChange={handleConfigToggle}
                            />
                        ) : (
                            <label className="flex justify-between items-center">
                                <span className="w-[40%] ">Betalningar</span>
                                <span style={{ color: 'red', fontSize: '11px' }}>
                                    (Betalning integration saknas)
                                </span>
                                <Switch
                                    onChange={() => null}
                                    checked={false}
                                    onColor="#22bbc4"
                                    offColor="#ccc"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                />
                            </label>
                        )}
                        <Input
                            label="Klarna Password"
                            name="klarnaPassword"
                            value={state.klarnaPassword}
                            handleInputChange={handleInputChange}
                            type="text"
                        />
                        <Toggle
                            label="CL bokningsystem"
                            name="careLabsBookingIntegration"
                            value={state.careLabsBookingIntegration}
                            handleToggleChange={handleToggle}
                        />

                        <Input
                            label="Klinik Namn:"
                            name="clinicName"
                            value={state.config.clinicName}
                            handleInputChange={updateConfig}
                            type="text"
                            required
                        />

                        <label className="flex justify-between items-center">
                            <span className="w-[40%] ">Barnavårdscentral</span>
                            <Switch
                                onChange={() => handleConfigToggle('isChildrenClinic')}
                                checked={state.config.isChildrenClinic}
                                onColor="#22bbc4"
                                offColor="#ccc"
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            <span className="w-[40%] ">Screen saver tid (sekundar):</span>
                            <input
                                type="number"
                                value={state.config.timer}
                                onChange={updateConfig}
                                name="timer"
                                className="input input-bordered input-primary w-[50%]"
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            <span className="w-[40%] ">Visa kommande bokningar</span>
                            <Switch
                                onChange={() => handleConfigToggle('showUpcomingBookings')}
                                checked={state.config.showUpcomingBookings}
                                onColor="#22bbc4"
                                offColor="#ccc"
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            <span className="w-[40%] ">Visa kommande bokningar inom (dagar):</span>
                            <input
                                type="number"
                                value={state.config.upcomingBookingsPeriod}
                                onChange={updateConfig}
                                name="upcomingBookingsPeriod"
                                className="input input-bordered input-primary w-[50%]"
                            />
                        </label>

                        <label className="flex justify-between items-center">
                            <span className="w-[40%] ">Screening</span>
                            <Switch
                                onChange={() => handleConfigToggle('screening')}
                                checked={state.config.screening}
                                onColor="#22bbc4"
                                offColor="#ccc"
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            <span className="w-[40%] ">Sen ankomst marginal(minuter):</span>
                            <input
                                type="number"
                                value={state.config.lateArrivalMargin}
                                onChange={updateConfig}
                                name="lateArrivalMargin"
                                className="input input-bordered input-primary w-[50%]"
                            />
                        </label>

                        <label className="flex justify-between items-center">
                            <span className="w-[40%] ">Survey</span>
                            <Switch
                                onChange={() => handleConfigToggle('survey')}
                                checked={state.config.survey}
                                onColor="#22bbc4"
                                offColor="#ccc"
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            <span className="w-[40%] ">E-Post:</span>
                            <input
                                type="text"
                                value={state.config.email ?? ''}
                                onChange={updateConfig}
                                name="email"
                                className="input input-bordered input-primary w-[50%]"
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            <span className="w-[40%] ">Logo</span>
                            <input className="hidden" type="file" onChange={handleFileChange} />
                            <img
                                src={state.config.logo}
                                alt=""
                                width="80px"
                                className="cursor-pointer"
                            />
                        </label>

                        <label className="flex justify-between items-center">
                            <span className="w-[30%] ">WebDoc ID:</span>
                            <input
                                type="text"
                                value={state.webDocClientId ?? ''}
                                name="webDocClientId"
                                onChange={handleInputChange}
                                className="input input-bordered input-primary w-[50%]"
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            <span className="w-[30%] ">WebDoc Clinic ID:</span>
                            <input
                                type="text"
                                value={state.webDocClinicId ?? ''}
                                name="webDocClinicId"
                                onChange={handleInputChange}
                                className="input input-bordered input-primary w-[50%]"
                            />
                        </label>
                        <label className="flex justify-between items-center">
                            <span className="w-[30%] ">WebDoc Secret:</span>
                            <input
                                type="text"
                                value={state.webDocClientSecret ?? ''}
                                name="webDocClientSecret"
                                onChange={handleInputChange}
                                className="input input-bordered input-primary w-[50%]"
                            />
                        </label>
                    </div>

                    <button
                        type="submit"
                        className={`h-12 w-[20%] btn text-white ${
                            state.clinicID === '' ? 'bg-gray-300' : ' bg-hs-blue'
                        }`}
                        disabled={!state.clinicID}
                    >
                        Skicka
                    </button>
                </form>
            </div>
        </Wrapper>
    );
};

export default NewClinic;
