import React, { FC } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { signOutUser } from 'firebase-service';
import { Settings } from 'types';
import LogoutIcon from '@heroicons/react/24/outline/ArrowRightOnRectangleIcon';
import TestIcon from '@heroicons/react/24/outline/CommandLineIcon';
import OfficeIcon from '@heroicons/react/24/outline/BuildingOffice2Icon';
import { getRoutes } from 'routes/sidebar.routes';

type Props = {
    settings: Settings;
};

export const Sidebar: FC<Props> = ({ settings }) => {
    const navigate = useNavigate();

    const signOut = async () => {
        await signOutUser();
        navigate('/login');
    };

    const routes = getRoutes(settings);

    return (
        <div className="drawer-side">
            <ul className="menu  pt-2 w-80 bg-base-100 text-base-content min-h-screen max-h-screen">
                <li className="pl-4 pb-10 pt-5 font-semibold text-2xl text-hs-blue">
                    {settings && settings.config.clinicName}
                </li>
                {routes.map((route, k) => {
                    return route.display ? (
                        <li className="" key={k}>
                            <NavLink
                                end
                                to={route?.path}
                                className={({ isActive }) =>
                                    `${
                                        isActive
                                            ? 'font-semibold  bg-hs-gray border-r-4 border-r-hs-blue '
                                            : 'font-normal border-r-4  border-r-white'
                                    }`
                                }
                            >
                                {route?.icon} {route?.name}
                            </NavLink>
                        </li>
                    ) : null;
                })}
                {settings.superAdmin && (
                    <>
                        <li className="">
                            <NavLink
                                end
                                to="/addclinic"
                                className={({ isActive }) =>
                                    `${
                                        isActive
                                            ? 'font-semibold  bg-hs-gray border-r-4 border-r-hs-blue '
                                            : 'font-normal border-r-4  border-r-white'
                                    }`
                                }
                            >
                                <OfficeIcon className="w-6 h-6" />
                                Add Clinic
                            </NavLink>
                        </li>
                        <li className="">
                            <NavLink
                                end
                                to="/testbooking"
                                className={({ isActive }) =>
                                    `${
                                        isActive
                                            ? 'font-semibold  bg-hs-gray border-r-4 border-r-hs-blue '
                                            : 'font-normal border-r-4  border-r-white'
                                    }`
                                }
                            >
                                <TestIcon className="w-6 h-6" />
                                Test booking
                            </NavLink>
                        </li>
                    </>
                )}
                <li onClick={signOut} className="absolute bottom-7 ">
                    <NavLink
                        to="/login"
                        className="font-normal border-r-4  border-r-white hover:bg-white"
                    >
                        <LogoutIcon className="w-6 h-6" />
                        Log out
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};
