import React, { FC, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import Home from 'pages/Dashboard';
import Loading from 'pages/Loading/loading.page';
import Login from 'pages/Login';
import Schema from 'pages/Schema';
import Staff from 'pages/Staff/staff.page';
import Treatments from 'pages/Treatments/treatments .page';
import SettingsPage from 'pages/Settings';
import NewClinic from 'pages/NewClinic/NewClinic';
import { Layout } from 'components/Layout';
import { useSettings } from 'hooks/SettingsContext';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { Settings } from 'types';
import { PrivateRoute } from 'components/PrivateRoute/privateRoute';
import { TestBooking } from 'pages/TestBooking';
const App: FC = () => {
    const { isLoading, clinic } = useAuth();
    const { settings, setSettings } = useSettings();

    useEffect(() => {
        if (!clinic) return undefined;
        const db = getFirestore();
        const docRef = doc(db, 'clinicSettings', clinic);
        const unSubSettings = onSnapshot(docRef, (snapshotSettings) => {
            setSettings(snapshotSettings.data() as Settings);
        });
        return () => unSubSettings();
    }, [clinic]);

    return !isLoading ? (
        <>
            <Layout>
                <Routes>
                    <Route element={<PrivateRoute />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/staff" element={<Staff />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/treatments" element={<Treatments />} />
                        <Route path="/schema" element={<Schema />} />
                        {settings?.superAdmin && (
                            <>
                                <Route path="/addclinic" element={<NewClinic />} />
                                <Route path="/testbooking" element={<TestBooking />} />
                            </>
                        )}
                    </Route>
                    <Route path="*" element={<Login />} />
                </Routes>
            </Layout>
        </>
    ) : (
        <Loading />
    );
};

export default App;
