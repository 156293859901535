import React, { FC, useMemo, useState } from 'react';
import useWeek from 'hooks/useWeek';
import moment from 'moment';
import { Appointment } from 'types';

type AmountInputFieldProps = {
    appointments: Appointment[];
    appointmentAt: Date;
    handleAppointmentClick: (time: Date, isSelected: boolean) => void;
};

const AmountInputField: FC<AmountInputFieldProps> = ({
    appointments,
    appointmentAt,
    handleAppointmentClick,
}) => {
    const { currentTime } = useWeek();
    const [isSelected, setIsSelected] = useState(false);
    const isDisabled = useMemo(() => {
        const currentTimeMoment = moment(currentTime);
        const appointmentAtMoment = moment(appointmentAt);
        if (appointments && appointments?.length > 0) {
            setIsSelected(false);
            return true;
        }
        return currentTimeMoment.isAfter(appointmentAtMoment);
    }, [currentTime, appointmentAt]);
    // eslint-disable-next-line no-console
    const handleClick = (time: Date) => {
        setIsSelected(!isSelected);
        handleAppointmentClick(time, isSelected);
    };
    return (
        <div
            onClick={() => !isDisabled && handleClick(appointmentAt)}
            className={`flex items-center justify-center w-[5vw] h-10 cursor-pointer ${
                isDisabled && 'bg-gray-100'
            } ${isSelected && 'bg-hs-blue bg-opacity-50'}`}
        >
            {appointments &&
                appointments?.map((appoinment: Appointment, i: number) => {
                    return (
                        <p className="text-center" key={i}>
                            {appoinment.treatment.name}
                        </p>
                    );
                })}
        </div>
    );
};

export default AmountInputField;
