import React, { FC } from 'react';
import Switch from 'react-switch';

interface PropsType {
    label: string;
    name: string;
    value: boolean;
    handleToggleChange: (val: string) => void;
}

export const Toggle: FC<PropsType> = ({ label, name, value, handleToggleChange }) => {
    return (
        <label className="flex justify-end gap-10 items-center">
            <span className="w-[40%] ">{label}</span>
            <Switch
                onChange={() => handleToggleChange(name)}
                checked={value}
                onColor="#003B5C"
                offColor="#ccc"
                checkedIcon={false}
                uncheckedIcon={false}
            />
        </label>
    );
};
