import React, { FC, ReactNode } from 'react';

interface Props {
    title: string;
    button?: ReactNode;
    children: ReactNode;
}

export const Wrapper: FC<Props> = ({ title, children, button }) => {
    return (
        <div className="card w-full p-6 bg-base-100 shadow-xl mt-6">
            <div className={`text-xl font-semibold ${button && 'inline-block'}`}>
                {title}
                {button && <div className="inline-block float-right">{button}</div>}
            </div>

            <div className="divider mt-2" />

            <div className="h-full w-full pb-6 bg-base-100">{children}</div>
        </div>
    );
};
