import React, { FC, ReactNode } from 'react';
import { Sidebar } from 'features/Sidebar';
import { useSettings } from 'hooks/SettingsContext';
import { useAuth } from 'hooks/useAuth';

interface Props {
    children: ReactNode;
}

export const Layout: FC<Props> = ({ children }) => {
    const { settings } = useSettings();
    const { authUser } = useAuth();

    return (
        <div className="flex">
            {settings && authUser && <Sidebar settings={settings} />}
            <div className="p-5 w-full max-h-screen overflow-y-scroll">{children}</div>
        </div>
    );
};
