import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { CareGiver, Treatment } from 'types';
import {
    getFirestore,
    collection,
    getDocs,
    doc,
    setDoc,
    updateDoc,
    onSnapshot,
    query,
    getDoc,
} from 'firebase/firestore';
import { useAuth } from 'hooks/useAuth';
import { DeleteModal } from '../deleteModal';
import { useSettings } from 'hooks/SettingsContext';
import UserPlusIcon from '@heroicons/react/24/outline/UserPlusIcon';
import TrashIcon from '@heroicons/react/24/outline/TrashIcon';
import EditIcon from '@heroicons/react/24/outline/PencilSquareIcon';
import { Wrapper } from 'features/Wrapper';
import maleAvatar from '../../assets/male.jpeg';
import femaleAvatar from '../../assets/female.jpeg';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

export const Staff = () => {
    const { settings } = useSettings();
    const [staffData, setStaffData] = useState<CareGiver>({
        personalNumber: '',
        firstName: '',
        id: '',
        lastName: '',
        title: '',
        waitingRoom: '',
        floor: '',
        treatments: [],
        profilePic: '',
    });
    const [caregivers, updateCaregivers] = useState<CareGiver[]>([]);
    const [treatments, updateTreatments] = useState<Treatment[]>();
    const { clinic } = useAuth();
    const [status, setStatus] = useState('');
    const [error, setError] = useState(false);
    const [action, setAction] = useState('add');

    useEffect(() => {
        if (!clinic) return undefined;
        const db = getFirestore();
        const queryCaregivers = query(collection(db, 'clinicSettings', clinic, 'caregivers'));
        const unsubscribeCaregivers = onSnapshot(queryCaregivers, (caregiversSnapshot) => {
            caregiversSnapshot.forEach((doc) => {
                updateCaregivers([...caregivers, doc.data() as CareGiver]);
            });
        });
        return () => {
            unsubscribeCaregivers();
        };
    }, []);

    useEffect(() => {
        if (!clinic) return undefined;
        const getCaregivers = async () => {
            const db = getFirestore();
            const caregiversColRef = collection(db, 'clinicSettings', clinic, 'caregivers');
            const qSnap = await getDocs(caregiversColRef);
            const caregiversData = qSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            updateCaregivers(caregiversData as CareGiver[]);
        };
        getCaregivers();
        return () => {
            getCaregivers();
        };
    }, [clinic, staffData]);

    useEffect(() => {
        if (!clinic) return undefined;
        const getTreatments = async () => {
            const db = getFirestore();
            const treatmentsColRef = collection(db, 'clinicSettings', clinic, 'treatments');
            const qSnap = await getDocs(treatmentsColRef);
            const treatmentsData = qSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            updateTreatments(treatmentsData as Treatment[]);
        };
        getTreatments();
        return () => {
            getTreatments();
        };
    }, [clinic]);

    const handleSelectTreatment = async (treatmentId: string) => {
        if (!clinic) return;
        if (staffData.treatments) {
            const db = getFirestore();
            const treatmentsDocRef = doc(
                collection(db, 'clinicSettings', clinic, 'treatments'),
                treatmentId
            );
            const treatmentCaregivers = (await getDoc(treatmentsDocRef)).data()?.caregivers;
            if (staffData?.treatments && staffData?.treatments.includes(treatmentId)) {
                const filteredCaregivers = treatmentCaregivers.filter(
                    (cg: string) => cg !== staffData.id
                );
                updateDoc(treatmentsDocRef, {
                    caregivers: filteredCaregivers,
                });
                const filteredTreatments = staffData?.treatments.filter(
                    (treatment) => treatment !== treatmentId
                );
                setStaffData({ ...staffData, treatments: filteredTreatments } as CareGiver);
                return;
            }
            updateDoc(treatmentsDocRef, {
                caregivers: [...treatmentCaregivers, staffData.id],
            });
            setStaffData({
                ...staffData,
                treatments: [...staffData.treatments, treatmentId],
            } as CareGiver);
        }
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setStaffData({ ...staffData, [e.target.name]: e.target.value });
    };

    const handleStaffForm = (e: FormEvent<HTMLFormElement>) => {
        if (!clinic) return;
        e.preventDefault();
        const db = getFirestore();
        if (staffData.id) {
            const caregiverRef = doc(
                collection(db, 'clinicSettings', clinic, 'caregivers'),
                staffData.id
            );
            setDoc(caregiverRef, staffData, { merge: true })
                .then(() => setStatus('Uppdaterad'))
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                    setError(true);
                    setStatus('Uppdatering misslyckades');
                });
            return;
        }

        const caregiverRef = doc(
            collection(db, 'clinicSettings', clinic, 'caregivers'),
            staffData.personalNumber
        );
        setStaffData({ ...staffData, id: staffData.personalNumber });
        setDoc(caregiverRef, {
            ...staffData,
            id: staffData.personalNumber,
        })
            .then(() => setStatus('Skickad'))
            .catch(() => {
                setError(true);
                setStatus('Ett fel uppstod');
            });
    };
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !clinic) return;
        const storage = getStorage();
        const storageRef = ref(storage, `images/${clinic}/doctors/${staffData.personalNumber}`);
        const snapshot = await uploadBytes(storageRef, e.target.files[0]);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setStaffData({ ...staffData, profilePic: downloadURL });
    };
    const getAvatar = (perNumber: string) => {
        const fourDigits = perNumber.slice(9);
        if (+fourDigits[2] % 2 === 0) {
            return femaleAvatar;
        }
        return maleAvatar;
    };
    return (
        <Wrapper
            title="Doctors"
            button={
                <label
                    htmlFor="my-modal"
                    className="btn gap-2 w-[180px] bg-hs-blue mb-5 hover:bg-secondary"
                    onClick={() => setAction('add')}
                >
                    <UserPlusIcon className="w-6 h-6" />
                    Doctor
                </label>
            }
        >
            <div className="overflow-x-auto w-full">
                <table className="table w-full">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Social Security No.</th>
                            <th>Floor</th>
                            <th>Waiting hall</th>
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {caregivers &&
                            caregivers.map((caregiver) => {
                                return (
                                    <tr key={caregiver.id}>
                                        <td>
                                            <div className="flex items-center space-x-3">
                                                <div className="avatar">
                                                    <div className="mask mask-squircle w-12 h-12">
                                                        <img
                                                            src={
                                                                caregiver.profilePic ??
                                                                getAvatar(caregiver.personalNumber)
                                                            }
                                                            alt="Avatar"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="font-bold">
                                                        {caregiver.firstName}
                                                    </div>
                                                    <div className="text-sm opacity-50">
                                                        {caregiver.lastName}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{caregiver.personalNumber}</td>
                                        <td className="pl-10">{caregiver.floor}</td>
                                        <td className="pl-10">{caregiver.waitingRoom}</td>
                                        <td>
                                            <label
                                                htmlFor="my-modal"
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setAction('edit');
                                                    setStaffData(caregiver);
                                                }}
                                            >
                                                <EditIcon className="w-6 h-6 " />
                                            </label>
                                        </td>
                                        <td>
                                            <a
                                                href="#del_modal"
                                                className="text-end"
                                                onClick={() => setStaffData(caregiver)}
                                            >
                                                <TrashIcon className="w-6 h-6 text-hs-red" />
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>

            <input type="checkbox" id="my-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box">
                    <h3 className="font-semibold text-lg mb-3">
                        {action === 'edit' ? 'Edit Doctor' : 'Add Doctor'}
                    </h3>
                    <form className="flex flex-col gap-y-3" onSubmit={handleStaffForm}>
                        {settings?.careLabsBookingIntegration && (
                            <label>
                                Title
                                <input
                                    type="text"
                                    placeholder="Surgeon"
                                    className="input input-bordered w-full"
                                    name="title"
                                    value={staffData.title}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                        )}

                        <label>
                            Social Security No.
                            <input
                                type="text"
                                placeholder="xxxxxxxx-xxxx"
                                className="input  w-full input-bordered"
                                name="personalNumber"
                                value={staffData.personalNumber}
                                onChange={handleInputChange}
                                required
                                pattern="^\d{8}-\d{4}$"
                                maxLength={13}
                            />
                        </label>

                        <label>
                            First Name
                            <input
                                type="text"
                                placeholder="John"
                                className="input input-bordered w-full"
                                name="firstName"
                                value={staffData.firstName}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <label>
                            Last Name
                            <input
                                type="text"
                                placeholder="Doe"
                                className="input input-bordered w-full"
                                name="lastName"
                                value={staffData.lastName}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <label>
                            Floor
                            <input
                                type="text"
                                placeholder="2"
                                className="input input-bordered w-full"
                                name="floor"
                                value={staffData.floor}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <label>
                            Waiting hall
                            <input
                                type="text"
                                placeholder="A"
                                className="input input-bordered w-full"
                                name="waitingRoom"
                                value={staffData.waitingRoom}
                                onChange={handleInputChange}
                            />
                        </label>

                        {settings?.careLabsBookingIntegration ? (
                            <>
                                <p>Treatments</p>

                                <div className="grid grid-cols-2 ">
                                    {treatments &&
                                        treatments.map((treatment) => {
                                            return (
                                                <label
                                                    key={treatment.id}
                                                    className="label cursor-pointer flex justify-start gap-x-2"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox checkbox-secondary"
                                                        checked={
                                                            staffData?.treatments &&
                                                            staffData?.treatments.includes(
                                                                treatment.id
                                                            )
                                                        }
                                                        onChange={() =>
                                                            handleSelectTreatment(treatment.id)
                                                        }
                                                    />
                                                    <span className="label-text">
                                                        {treatment.name}
                                                    </span>
                                                </label>
                                            );
                                        })}
                                </div>
                            </>
                        ) : null}
                        <div className="mt-4">
                            <label className="flex flex-col justify-start gap-5 items-start">
                                <span className="w-[40%] ">Profile picture</span>
                                <div className="flex items-center">
                                    <input
                                        type="file"
                                        className="file-input file-input-sm"
                                        onChange={handleFileChange}
                                    />
                                    <img
                                        src={staffData.profilePic}
                                        alt=""
                                        width="80px"
                                        className="cursor-pointer"
                                    />
                                </div>
                            </label>
                        </div>

                        <div className="flex justify-between w-full items-end">
                            <div>
                                <p className={`${error ? 'text-error' : 'text-success'}`}>
                                    {status}
                                </p>
                            </div>
                            <div className="modal-action">
                                {staffData.personalNumber ? (
                                    <button className="btn bg-hs-blue w-[100px]" type="submit">
                                        {action === 'edit' ? 'Update' : 'Add'}
                                    </button>
                                ) : (
                                    <button className="btn" disabled>
                                        {action === 'edit' ? ' Update' : 'Add'}
                                    </button>
                                )}
                                <label
                                    htmlFor="my-modal"
                                    className="btn btn-outline  text-hs-blue  border border-hs-blue"
                                    onClick={() => {
                                        setStaffData({
                                            personalNumber: '',
                                            firstName: '',
                                            id: '',
                                            lastName: '',
                                            title: '',
                                            waitingRoom: '',
                                            floor: '',
                                            treatments: [],
                                        });
                                        setStatus('');
                                    }}
                                >
                                    Close
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <DeleteModal
                clinic={clinic ?? ''}
                error={error}
                status={status}
                setStatus={setStatus}
                setError={setError}
                setDoctor={setStaffData}
                doctorId={staffData.id}
            />
        </Wrapper>
    );
};
