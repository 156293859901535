/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Switch from 'react-switch';
import { collection, doc, getFirestore, updateDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from 'hooks/useAuth';
import { useSettings } from 'hooks/SettingsContext';
import { Wrapper } from 'features/Wrapper';
import { Toggle } from 'components/Toggle';

const SettingsPage = () => {
    const { settings } = useSettings();
    const [state, setState] = useState<any>({
        careLabsBookingIntegration: true,
        webDocIntegration: false,
        config: {
            clinicName: '',
            acceptPayment: false,
            email: '',
            lateArrivalMargin: 5,
            screening: false,
            showUpcomingBookings: false,
            upcomingBookingsPeriod: 0,
            timer: 30,
        },
    });
    useEffect(() => {
        if (settings) setState(settings);
    }, [settings]);
    // eslint-disable-next-line no-console
    console.log(settings);
    const { clinic } = useAuth();
    const notify = (status: string) => {
        if (status === 'success') {
            toast.success('Uppdatering lyckades', {
                autoClose: 1000,
            });
        }
        if (status === 'error') {
            toast.error('Uppdatering misslyckades', {
                autoClose: 1000,
            });
        }
    };
    // eslint-disable-next-line no-console
    console.log(state.careLabsBookingIntegration, state.webDocIntegration);
    const handleConfigToggle = (id: string) => {
        setState({ ...state, config: { ...state.config, [id]: !state.config[id] } });
    };
    const handleToggle = (id: string) => {
        if (id === 'careLabsBookingIntegration') {
            setState({ ...state, [id]: !state[id], webDocIntegration: !state.webDocIntegration });
            return;
        }
        if (id === 'webDocIntegration') {
            setState({
                ...state,
                [id]: !state[id],
                careLabsBookingIntegration: !state.careLabsBookingIntegration,
            });
            return;
        }
    };
    const updateConfig = (e: ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, config: { ...state.config, [e.target.name]: e.target.value } });
    };
    const handleFormSubmit = async (e: FormEvent) => {
        if (!clinic) return;
        e.preventDefault();
        const db = getFirestore();
        const collRef = collection(db, 'clinicSettings');
        const docRef = doc(collRef, clinic);
        try {
            updateDoc(docRef, {
                careLabsBookingIntegration: state.careLabsBookingIntegration,
                webDocIntegration: state.webDocIntegration,
                config: {
                    ...state.config,
                },
            });
            notify('success');
        } catch (error) {
            notify('error');
        }
    };

    return (
        <Wrapper title="Settings">
            <ToastContainer />
            <div className="p-8">
                <form onSubmit={handleFormSubmit} className="flex flex-col items-center gap-y-12">
                    <div className="flex justify-center gap-10 items-start w-full">
                        <div className="flex flex-col w-1/2 gap-5 justify-start">
                            <label className="flex justify-between items-center">
                                <span className="w-[30%] ">Clinic Name:</span>
                                <input
                                    type="text"
                                    value={state.config.clinicName}
                                    name="clinicName"
                                    onChange={updateConfig}
                                    className="input input-bordered input-primary w-[50%]"
                                />
                            </label>
                            <label className="flex justify-between items-center">
                                <span className="w-[30%] ">E-Mail:</span>
                                <input
                                    type="text"
                                    value={state.config.email ?? ''}
                                    onChange={updateConfig}
                                    name="email"
                                    className="input input-bordered input-primary w-[50%]"
                                />
                            </label>
                            <label className="flex justify-between items-center">
                                <span className="w-[30%] ">Screen Timeout (Seconds):</span>
                                <input
                                    type="number"
                                    value={state.config.timer}
                                    onChange={updateConfig}
                                    name="timer"
                                    className="input input-bordered input-primary w-[50%]"
                                />
                            </label>
                            <label className="flex justify-between items-center">
                                <span className="w-[40%] ">Show upcoming bookings in (days):</span>
                                <input
                                    type="number"
                                    value={state.config.upcomingBookingsPeriod}
                                    onChange={updateConfig}
                                    name="upcomingBookingsPeriod"
                                    className="input input-bordered input-primary w-[50%]"
                                />
                            </label>
                            <label className="flex justify-between items-center">
                                <span className="w-[40%] ">Late arrival margin(minutes):</span>
                                <input
                                    type="number"
                                    value={state.config.lateArrivalMargin}
                                    onChange={updateConfig}
                                    name="lateArrivalMargin"
                                    className="input input-bordered input-primary w-[50%]"
                                />
                            </label>
                        </div>
                        <div className="flex flex-col w-1/3 gap-5 justify-start">
                            {settings?.superAdmin && (
                                <>
                                    <Toggle
                                        label="Use CARELABS"
                                        name="careLabsBookingIntegration"
                                        value={state.careLabsBookingIntegration}
                                        handleToggleChange={handleToggle}
                                    />
                                    <Toggle
                                        label="Use WEBDOC"
                                        name="webDocIntegration"
                                        value={state.webDocIntegration}
                                        handleToggleChange={handleToggle}
                                    />
                                    <label className="flex justify-end gap-10 items-center">
                                        <span className="w-[40%] ">Payments</span>
                                        <Switch
                                            onChange={() => handleConfigToggle('acceptPayment')}
                                            checked={state.config.acceptPayment}
                                            onColor="#003B5C"
                                            offColor="#ccc"
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                        />
                                    </label>
                                </>
                            )}
                            <label className="flex justify-end gap-10 items-center">
                                <span className="w-[40%] ">Show upcoming booking</span>
                                <Switch
                                    onChange={() => handleConfigToggle('showUpcomingBookings')}
                                    checked={state.config.showUpcomingBookings}
                                    onColor="#003B5C"
                                    offColor="#ccc"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                />
                            </label>
                            <label className="flex justify-end gap-10 items-center">
                                <span className="w-[40%] ">Screening</span>
                                <Switch
                                    onChange={() => handleConfigToggle('screening')}
                                    checked={state.config.screening}
                                    onColor="#003B5C"
                                    offColor="#ccc"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                />
                            </label>
                        </div>
                    </div>
                    <button type="submit" className="h-12 w-[20%] btn text-white bg-hs-blue">
                        Save
                    </button>
                </form>
            </div>
        </Wrapper>
    );
};

export default SettingsPage;
