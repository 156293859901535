import React from 'react';

const Home = () => {
    return (
        <div className="flex flex-col items-start m-5 w-full  gap-5">
            <h1 className="text-xl">Welcome</h1>
        </div>
    );
};

export default Home;
