import React, { ChangeEvent, FC } from 'react';

interface PropsType {
    label: string;
    name: string;
    value: string;
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    type: string;
}

export const Input: FC<PropsType> = ({ label, name, value, handleInputChange, required, type }) => {
    return (
        <label className="flex justify-between items-center">
            <span className="w-[30%] ">{label}</span>
            <input
                type={type}
                value={value ?? ''}
                name={name}
                onChange={handleInputChange}
                className="input input-bordered input-primary w-[50%]"
                required={required}
            />
        </label>
    );
};
