import { collection, deleteDoc, doc, getFirestore } from 'firebase/firestore';
import React, { FC } from 'react';
import { CareGiver } from 'types';

interface PropsType {
    clinic: string;
    error: boolean;
    status: string;
    setStatus: (val: string) => void;
    setError: (val: boolean) => void;
    setDoctor: (val: CareGiver) => void;
    doctorId: string;
}

export const DeleteModal: FC<PropsType> = ({
    clinic,
    error,
    status,
    setStatus,
    setError,
    setDoctor,
    doctorId,
}) => {
    const db = getFirestore();
    const deleteDoctor = (id: string) => {
        if (!clinic) return;
        const doctorRef = doc(collection(db, 'clinicSettings', clinic, 'caregivers'), id);
        deleteDoc(doctorRef)
            .then(() => setStatus('Raderat'))
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                setError(true);
                setStatus('misslyckad');
            });
    };
    return (
        <div className="modal" id="del_modal">
            <div className="modal-box">
                <h3 className="font-semibold text-lg text-hs-red">Delete Doctor</h3>
                <p className="py-4">Du you want to delete this Doctor from the list?</p>
                <div className="flex justify-between w-full items-end">
                    <div>
                        <p className={`${error ? 'text-error' : 'text-success'}`}>{status}</p>
                    </div>
                    <div className="modal-action">
                        <button
                            className="btn bg-hs-red border-none hover:bg-error"
                            onClick={() => deleteDoctor(doctorId)}
                        >
                            Delete
                        </button>
                        <a
                            href="#"
                            className="btn btn-outline  text-hs-blue  border border-hs-blue"
                            onClick={() => {
                                setDoctor({
                                    id: '',
                                    title: '',
                                    firstName: '',
                                    lastName: '',
                                    personalNumber: '',
                                    waitingRoom: '',
                                    floor: '',
                                });
                                setStatus('');
                            }}
                        >
                            Cancel
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
