import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { SettingsProvider } from 'hooks/SettingsContext';
import { AuthProvider } from 'hooks/useAuth';
const container = document.getElementById('root') as Element;
const root = createRoot(container);
root.render(
    <Router>
        <AuthProvider>
            <SettingsProvider>
                <App />
            </SettingsProvider>
        </AuthProvider>
    </Router>
);
