import React from 'react';
// import DashboardIcon from '@heroicons/react/24/outline/Squares2X2Icon';
import CalendarIcon from '@heroicons/react/24/outline/CalendarDaysIcon';
import UsersIcon from '@heroicons/react/24/outline/UsersIcon';
import DocumentListIcon from '@heroicons/react/24/outline/ClipboardDocumentListIcon';
import SettingsIcon from '@heroicons/react/24/outline/AdjustmentsVerticalIcon';
import { Settings } from 'types';
const iconClasses = 'h-6 w-6';

export const getRoutes = (settings: Settings) => {
    const routes = [
        // {
        //     path: '/',
        //     icon: <DashboardIcon className={iconClasses} />,
        //     name: 'Dashboard',
        //     display: true,
        // },

        {
            path: '/staff',
            icon: <UsersIcon className={iconClasses} />,
            name: 'Doctors',
            display: true,
        },
        {
            path: '/treatments',
            icon: <DocumentListIcon className={iconClasses} />,
            name: 'Treatments',
            display: settings?.superAdmin || settings?.careLabsBookingIntegration,
        },
        {
            path: '/schema',
            icon: <CalendarIcon className={iconClasses} />,
            name: 'Schedule',
            display: settings?.superAdmin || settings?.careLabsBookingIntegration,
        },
        {
            path: '/settings',
            icon: <SettingsIcon className={iconClasses} />,
            name: 'Settings',
            display: true,
        },
    ];
    return routes;
};
