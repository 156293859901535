import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { Day } from 'types';
import 'moment/locale/sv';
moment.locale('se');

type UseWeekRetunType = {
    week: { weekdays: Day[]; weekNumber: number };
    year: string;
    today: Day;
    currentTime: number;
    nextWeek: () => void;
    prevWeek: () => void;
};

const momentToDay = (currentDate: Moment): Day => {
    return {
        weekday: currentDate.format('ddd'),
        date: currentDate.toDate(),
        month: currentDate.format('MMMM'),
        year: currentDate.format('YYYY'),
    };
};

const useWeek = (): UseWeekRetunType => {
    const [currentWeek, setCurrentWeek] = useState(0);

    const getToday = (currentDate: Moment): { day: Day; time: number } => {
        return { day: momentToDay(currentDate), time: currentDate.unix() * 1000 };
    };

    const getWeek = (currentDate: Moment): { weekdays: Day[]; weekNumber: number } => {
        const weekStart = currentDate.clone();
        const weekdays: Day[] = [];
        for (let i = 0; i <= 6; i++) {
            const day = weekStart.clone().add(i, 'day');
            weekdays.push(momentToDay(day));
        }
        return {
            weekdays,
            weekNumber: weekStart.isoWeek(),
        };
    };
    const [today, setToday] = useState(getToday(moment()));
    const [selectedWeek, setSelectedWeek] = useState(
        getWeek(moment().startOf('day').add(currentWeek, 'w'))
    );
    const nextWeek = () => {
        setCurrentWeek(currentWeek + 1);
    };

    const prevWeek = () => {
        setCurrentWeek(currentWeek - 1);
    };

    useEffect(() => {
        const setTime = () => {
            setSelectedWeek(getWeek(moment().startOf('day').add(currentWeek, 'w')));
            setToday(getToday(moment()));
        };
        setTime();
        const timerID = setInterval(setTime, 60000);
        return () => clearInterval(timerID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWeek]);

    return {
        week: selectedWeek,
        today: today.day,
        currentTime: today.time,
        nextWeek,
        prevWeek,
        year: selectedWeek.weekdays[0].year,
    };
};

export default useWeek;
