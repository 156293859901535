import { User } from 'firebase/auth';
import React, {
    createContext,
    useContext,
    useState,
    FC,
    ReactElement,
    useEffect,
    Dispatch,
} from 'react';
import { authentication } from 'firebase-service';
import { SetStateAction } from 'react';

export type AuthReturnType = {
    authUser: User | null;
    isLoading: boolean;
    clinic: string | undefined;
    setClinic: Dispatch<SetStateAction<string | undefined>>;
};

const AuthContext = createContext<AuthReturnType>({
    authUser: null,
    isLoading: true,
    clinic: undefined,
    setClinic: () => undefined,
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAuth = () => useContext(AuthContext);

type AuthProviderProps = {
    children: ReactElement | ReactElement[];
};

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [authUser, setAuthUser] = useState<User | null>(null);
    const [clinic, setClinic] = useState<string>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const unlisten = authentication.onAuthStateChanged((user) => {
            setIsLoading(true);
            setAuthUser(user);
            if (user) {
                setClinic(user.email?.split('@')[0]);
            }
            setIsLoading(false);
        });
        return () => {
            unlisten();
        };
    }, []);

    return (
        <AuthContext.Provider value={{ authUser, isLoading, clinic, setClinic }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, useAuth };

// import { useEffect, useState } from 'react';
// import { authentication } from 'firebase-service';
// import { User } from 'firebase/auth';

// const useAuth = (): { authUser: User | null; isLoading: boolean; clinic: string | null } => {
//     const [authUser, setAuthUser] = useState<User | null>(null);
//     const [isLoading, setIsLoading] = useState(false);
//     useEffect(() => {
//         const unlisten = authentication.onAuthStateChanged((user) => {
//             setIsLoading(true);
//             setAuthUser(user);
//             setIsLoading(false);
//         });
//         return () => {
//             unlisten();
//         };
//     }, []);

//     return { authUser, isLoading, clinic: authUser?.email?.split('@')[0] ?? null };
// };

// export default useAuth;
