/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { getFirestore, collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import { useAuth } from 'hooks/useAuth';
import { Wrapper } from 'features/Wrapper';
import { encrypt } from '../crypto';

import moment from 'moment';

export const TestBooking = () => {
    const today = moment(new Date()).format('YYYY-MM-DD');
    const [patient, setPatient] = useState<any>({
        personalNumber: '198701264510',
        time: '14:00',
        date: today,
    });
    const { clinic } = useAuth();
    const [status, setStatus] = useState('');
    const [error, setError] = useState(false);
    const pnrs = ['198406106032', '201506279445', '191212121212', '198701264510'];
    const timeSlots = ['11:00', '13:00', '14:00', '15:00', '16:00', '23:00'];
    const dates = [today];
    for (let i = 0; i < 2; i++) {
        dates.push(moment(dates[i]).add(1, 'days').format('YYYY-MM-DD'));
    }

    const handleInputChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setPatient({ ...patient, [e.target.name]: e.target.value });
    };
    const encryptedSecurityNumber = encrypt(patient.personalNumber);

    const handleStaffForm = (e: FormEvent<HTMLFormElement>) => {
        if (!clinic) return;
        e.preventDefault();
        const db = getFirestore();
        const appointmentRef = doc(collection(db, 'clinicSettings', clinic, 'appointments'));
        setDoc(appointmentRef, {
            id: appointmentRef.id,
            clinic,
            arrived: 0,
            appointmentAt: patient.date,
            createdAt: new Timestamp(Date.now() / 1000, 0),
            updatedAt: new Timestamp(Date.now() / 1000, 0),
            patient: {
                personalNumber: encryptedSecurityNumber,
                socialSec: encryptedSecurityNumber,
                time: patient.time,
                date: patient.date,
            },
            status: 'created',
            caregiver: {
                personalNumber: '19870126-4510',
                firstName: 'Tarek',
                identifier: '19870126-4510',
                lastName: 'Mesbah',
                title: 'Dr.',
                waitingRoom: 'B',
                floor: '',
                treatments: [],
                profilePic: '',
            },
            treatment: {},
            published: true,
            payments: [
                {
                    price: 200,
                },
            ],
            type: 'scheduled',
            date: moment(patient.date).format('YYYY-MM-DD'),
            startTime: patient.time,
            endTime: patient.time + 15,
        })
            .then(() => {
                setStatus('Skickad');
                return setTimeout(() => {
                    setStatus('');
                }, 2000);
            })
            .catch(() => {
                setError(true);
                setStatus('Ett fel uppstod');
            });
    };

    return (
        <Wrapper title="Test Booking">
            <form className="flex flex-col gap-20" onSubmit={handleStaffForm}>
                <div className="flex justify-start gap-5">
                    <label>
                        <select
                            className="select select-primary w-full max-w-xs"
                            onChange={handleInputChange}
                            name="personalNumber"
                            value={patient.personalNumber}
                        >
                            {pnrs.map((pnr) => {
                                return (
                                    <option key={pnr} value={pnr}>
                                        {pnr}
                                    </option>
                                );
                            })}
                        </select>
                    </label>

                    <label>
                        <select
                            className="select select-primary w-full max-w-xs"
                            onChange={handleInputChange}
                            name="date"
                            value={patient.date}
                        >
                            {dates.map((date) => {
                                return (
                                    <option key={date} value={date}>
                                        {date}
                                    </option>
                                );
                            })}
                        </select>
                    </label>

                    <label>
                        <select
                            className="select select-primary w-full max-w-xs"
                            onChange={handleInputChange}
                            name="time"
                            value={patient.time}
                        >
                            {timeSlots.map((slot) => {
                                return (
                                    <option key={slot} value={slot}>
                                        {slot}
                                    </option>
                                );
                            })}
                        </select>
                    </label>
                </div>

                <div className="flex gap-10 justify-start items-center">
                    <button className="btn">Skicka</button>
                    <span className={`${error ? 'text-error' : 'text-success'}`}>{status}</span>
                </div>
            </form>
        </Wrapper>
    );
};
