/* eslint-disable no-console */
import useWeek from 'hooks/useWeek';
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import right from '../../images/icons/right.svg';
import left from '../../images/icons/left.svg';
import {
    getFirestore,
    onSnapshot,
    collection,
    query,
    where,
    doc,
    Timestamp,
    writeBatch,
    getDocs,
} from 'firebase/firestore';
import { useAuth } from 'hooks/useAuth';
import { Appointment, CareGiver, Treatment } from 'types';
import AmountInputField from 'components/AmountInputField';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Schema: FC = () => {
    const { authUser, clinic } = useAuth();
    const { week, today, prevWeek, nextWeek, year } = useWeek();
    const [careGiverId, updateCareGiverId] = useState<string>('');
    const [careGiver, updateCareGiver] = useState<CareGiver>();
    const [treatment, updateTreatment] = useState<Treatment>();
    const [appointments, setAppointments] = useState<Appointment[]>();
    const [appointmentsToPublish, setAppointmentsToPublish] = useState<Appointment[]>([]);
    const [treatments, updateTreatments] = useState<Treatment[]>();
    const [caregivers, updateCaregivers] = useState<CareGiver[]>();
    const db = getFirestore();

    useEffect(() => {
        if (!authUser || !clinic || !careGiverId) return undefined;
        const colRef = collection(db, 'clinicSettings', clinic, 'appointments');
        const qAppointments = query(colRef, where('caregiver.id', '==', careGiverId));
        const unSubAppointments = onSnapshot(qAppointments, (snapshotAppointments) => {
            const appointments = snapshotAppointments.docs.map((doc) => {
                return {
                    ...doc.data(),
                };
            });
            setAppointments(appointments as Appointment[]);
        });
        return () => unSubAppointments();
    }, [authUser, year, clinic, careGiverId]);

    useEffect(() => {
        if (!clinic) return undefined;
        const getTreatments = async () => {
            const treatmentsRef = collection(db, 'clinicSettings', clinic, 'treatments');
            const qSnap = await getDocs(treatmentsRef);
            const treatmentsData = qSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            updateTreatments(treatmentsData as Treatment[]);
        };
        getTreatments();
        return () => {
            getTreatments();
        };
    }, [clinic, careGiverId]);

    useEffect(() => {
        if (!clinic) return undefined;
        const getCaregivers = async () => {
            const caregiversRef = collection(db, 'clinicSettings', clinic, 'caregivers');
            const qSnap = await getDocs(caregiversRef);
            const treatmentsData = qSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            updateCaregivers(treatmentsData as CareGiver[]);
        };
        getCaregivers();
        return () => {
            getCaregivers();
        };
    }, [clinic]);

    const timeslots = useMemo(() => {
        return week.weekdays.map((day) => {
            const timestamps = [];
            const openTime = 8;
            const closeTime = 18;
            const divideBy = 10;
            const refMoment = moment(day.date);
            for (let index = openTime; index < closeTime; index++) {
                refMoment.set('hours', index);
                switch (divideBy as number) {
                    case 10:
                        for (let index = 0; index < 6; index++) {
                            const date = refMoment.toDate();
                            timestamps.push(date);
                            refMoment.add(10, 'minutes');
                        }
                        break;
                    case 15:
                        for (let index = 0; index < 4; index++) {
                            const date = refMoment.toDate();
                            timestamps.push(date);
                            refMoment.add(15, 'minutes');
                        }
                        break;
                    case 20:
                        for (let index = 0; index < 3; index++) {
                            const date = refMoment.toDate();
                            timestamps.push(date);
                            refMoment.add(20, 'minutes');
                        }
                        break;
                    case 30:
                        for (let index = 0; index < 2; index++) {
                            const date = refMoment.toDate();
                            timestamps.push(date);
                            refMoment.add(30, 'minutes');
                        }
                        break;
                    case 60:
                        for (let index = 0; index < 1; index++) {
                            const date = refMoment.toDate();
                            timestamps.push(date);
                            refMoment.add(60, 'minutes');
                        }
                        break;
                    default:
                        break;
                }
            }
            return timestamps;
        });
    }, [week.weekdays]);

    const amountOfAppointments = useMemo(() => {
        const datesInWeek = week.weekdays.map((day) => day.date);
        const total = datesInWeek.map(
            (date) =>
                appointments?.filter(
                    (appointment) =>
                        appointment.appointmentAt.toDate().toDateString() === date.toDateString()
                ).length
        );
        const published = datesInWeek.map(
            (date) =>
                appointments?.filter(
                    (appointment) =>
                        appointment.appointmentAt.toDate().toDateString() === date.toDateString() &&
                        appointment.published
                ).length
        );
        const booked = datesInWeek.map(
            (date) =>
                appointments?.filter(
                    (appointment) =>
                        appointment.appointmentAt.toDate().toDateString() === date.toDateString() &&
                        appointment.patient
                ).length
        );
        return { total, published, booked };
    }, [week, appointments]);
    const notify = (status: string) => {
        if (status === 'success') {
            toast.success('Publicering lyckades', {
                autoClose: 1000,
            });
        }
        if (status === 'error') {
            toast.error('Publicering misslyckades', {
                autoClose: 1000,
            });
        }
    };
    const publishWeek = async () => {
        if (!clinic) return;
        if (appointmentsToPublish && appointmentsToPublish?.length > 0) {
            const batch = writeBatch(db);
            appointmentsToPublish.map((appointment) => {
                const docRef = doc(collection(db, 'clinicSettings', clinic, 'appointments'));
                return batch.set(docRef, appointment);
            });
            await batch
                .commit()
                .then(() => notify('success'))
                .catch(() => notify('error'));
            setAppointmentsToPublish([]);
        }
    };

    const selectStaff = (e: ChangeEvent<HTMLSelectElement>) => {
        if (!caregivers) return;
        updateTreatment(undefined);
        updateCareGiverId(e.target.value);
        const careGiver = caregivers.find(({ id }: CareGiver) => id === e.target.value);
        updateCareGiver(careGiver);
    };
    const selectTreatment = (e: ChangeEvent<HTMLSelectElement>) => {
        if (!treatments) return;
        const treatment = treatments.find(({ id }: Treatment) => id === e.target.value);
        updateTreatment(treatment);
    };
    const filterAppointments = (time: Date) => {
        if (!appointments) return [];
        return appointments.filter(
            (appointment) => appointment.appointmentAt.toDate().toString() === time.toString()
        );
    };
    const handleAppointmentClick = (time: Date, isSelected: boolean) => {
        if (!treatment || !clinic) return;
        if (!isSelected) {
            setAppointmentsToPublish([
                ...appointmentsToPublish,
                {
                    clinic,
                    appointmentAt: new Timestamp(time.getTime() / 1000, 0),
                    createdAt: new Timestamp(Date.now() / 1000, 0),
                    updatedAt: new Timestamp(Date.now() / 1000, 0),
                    patient: null,
                    status: 'created',
                    caregiver: careGiver,
                    treatment: {
                        name: treatment.name,
                        id: treatment.id,
                    },
                    published: true,
                    payments: [
                        {
                            price: treatment.price,
                        },
                    ],
                    type: 'scheduled',
                    date: moment(time).format('YYYY-MM-DD'),
                    startTime: moment(time).format('HH:mm'),
                    endTime: moment(time).add(15, 'minutes').format('HH:mm'),
                },
            ]);
        }
        if (isSelected) {
            const filteredAppointments =
                appointmentsToPublish &&
                appointmentsToPublish.filter(
                    (appointment) =>
                        appointment.appointmentAt.toDate().toString() !== time.toString()
                );
            setAppointmentsToPublish(filteredAppointments);
        }
    };
    return (
        <>
            <ToastContainer />
            <div className="flex flex-col items-start w-full m-5 gap-5 max-h-[92vh] overflow-scroll">
                <div className="flex justify-start gap-5 items-center sticky">
                    <div className="flex justify-start gap-5 items-center">
                        <select onChange={selectStaff} className="select select-bordered max-w-xs">
                            <option value="">Choose Caregiver</option>
                            {caregivers &&
                                caregivers.map((caregiver) => {
                                    return (
                                        <option key={caregiver.id} value={caregiver.id}>
                                            {caregiver.firstName + ' ' + caregiver.lastName}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                    {careGiverId && (
                        <div className="flex justify-start gap-5 items-center">
                            <select
                                onChange={selectTreatment}
                                className="select select-bordered max-w-xs"
                            >
                                <option value="" selected={!treatment}>
                                    Choose treatment
                                </option>
                                {treatments &&
                                    treatments.map((treatment: Treatment, i: number) => {
                                        if (treatment.caregivers.includes(careGiverId)) {
                                            return (
                                                <option key={i} value={treatment.id}>
                                                    {treatment.name}
                                                </option>
                                            );
                                        }
                                        return null;
                                    })}
                            </select>
                        </div>
                    )}
                    <button
                        className={`btn btn-secondary w-[200px] ${
                            appointmentsToPublish.length < 1 && 'bg-slate-200 text-gray-300'
                        }`}
                        onClick={publishWeek}
                        disabled={appointmentsToPublish.length < 1}
                    >
                        Publicera vecka
                    </button>
                </div>
                {careGiverId && treatment && (
                    <>
                        <div className="flex justify-between w-1/2  p-5">
                            <div className="flex justify-around w-2/3 mx-auto">
                                <button onClick={prevWeek}>
                                    <img src={left} className="w-5 h-5" alt="" />
                                </button>
                                <p>{`${week.weekdays[0].month} v.${week.weekNumber}`}</p>
                                <button onClick={nextWeek}>
                                    <img src={right} className="w-5 h-5" alt="" />
                                </button>
                            </div>
                        </div>
                        <div className="max-w-screen-lg">
                            <div className="grid p-0 gap-0 rounded grid-cols-8 text-sm">
                                <div />
                                {week.weekdays.map(({ weekday, date }, index) => (
                                    <div
                                        key={date + weekday}
                                        className={`flex flex-col bg-gray-100 border-b border-t
                             ${index !== 6 ? ' border-l' : ' border-l border-r'}`}
                                    >
                                        <div
                                            className={`grid grid-rows-2 p-1 justify-items-center ${
                                                date.toString() === today.date.toString()
                                                    ? ' bg-hs-blue bg-opacity-10'
                                                    : ' bg-white border-white'
                                            } border md:border-none`}
                                        >
                                            <p>{weekday}</p>
                                            <p>{moment(date).format(' D/M')}</p>
                                        </div>
                                        <div className="grid grid-cols-2 h-5">
                                            <p className="w-full text-center">
                                                {amountOfAppointments.total[index]
                                                    ? `${amountOfAppointments.booked[index]}`
                                                    : ''}
                                            </p>
                                            <p
                                                className={`${
                                                    amountOfAppointments.published[index]
                                                        ? ' bg-green-100'
                                                        : ''
                                                } w-full text-center`}
                                            >
                                                {amountOfAppointments.total[index]
                                                    ? `${amountOfAppointments.published[index]}/${amountOfAppointments.total[index]}`
                                                    : ''}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="grid grid-cols-8 divide-x rounded text-sm content-center border bg-white overflow-scroll ">
                                <div className="grid divide-y" key={timeslots[0][0].toString()}>
                                    {timeslots[0].map((time) => (
                                        <div
                                            className="flex justify-center items-center"
                                            key={time.toString()}
                                        >
                                            {moment(time).format(' HH:mm')}
                                        </div>
                                    ))}
                                </div>
                                {timeslots.map((slotsOfWeek) => (
                                    <div className="grid divide-y" key={slotsOfWeek[0].toString()}>
                                        {slotsOfWeek.map((time) => (
                                            <AmountInputField
                                                appointmentAt={time}
                                                appointments={filterAppointments(time)}
                                                key={time.toString()}
                                                handleAppointmentClick={handleAppointmentClick}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Schema;
