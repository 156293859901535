import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { CareGiver, Treatment } from 'types';
import {
    getFirestore,
    collection,
    getDocs,
    doc,
    setDoc,
    updateDoc,
    onSnapshot,
    query,
    getDoc,
} from 'firebase/firestore';
import { useAuth } from 'hooks/useAuth';
import PlusIcon from '@heroicons/react/24/outline/PlusCircleIcon';

const Treatments = () => {
    const [treatments, updateTreatments] = useState<Treatment[]>([]);
    const [treatmentData, setTreatmentData] = useState<Treatment>({
        name: '',
        id: '',
        duration: +'',
        price: '',
        description: '',
        caregivers: [],
    });
    const [caregivers, updateCaregivers] = useState<CareGiver[]>([]);
    const { clinic } = useAuth();
    const [status, setStatus] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!clinic) return undefined;
        const db = getFirestore();
        const queryTreatments = query(collection(db, 'clinicSettings', clinic, 'treatments'));
        const unsubscribeTreatments = onSnapshot(queryTreatments, (treatmentsSnapshot) => {
            treatmentsSnapshot.forEach((doc) => {
                updateTreatments([...treatments, doc.data() as Treatment]);
            });
        });
        return () => {
            unsubscribeTreatments();
        };
    }, []);

    useEffect(() => {
        if (!clinic) return undefined;
        const getCaregivers = async () => {
            const db = getFirestore();
            const caregiversColRef = collection(db, 'clinicSettings', clinic, 'caregivers');
            const qSnap = await getDocs(caregiversColRef);
            const caregiversData = qSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            updateCaregivers(caregiversData as CareGiver[]);
        };
        getCaregivers();
        return () => {
            getCaregivers();
        };
    }, [clinic, treatmentData]);

    useEffect(() => {
        if (!clinic) return undefined;
        const getTreatments = async () => {
            const db = getFirestore();
            const treatmentsColRef = collection(db, 'clinicSettings', clinic, 'treatments');
            const qSnap = await getDocs(treatmentsColRef);
            const treatmentsData = qSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            updateTreatments(treatmentsData as Treatment[]);
        };
        getTreatments();
        return () => {
            getTreatments();
        };
    }, [clinic]);

    const selectCaregiver = async (caregiversId: string) => {
        if (!clinic) return;
        const db = getFirestore();
        const caregiverDocRef = doc(
            collection(db, 'clinicSettings', clinic, 'caregivers'),
            caregiversId
        );
        const caregiverTreatments = (await getDoc(caregiverDocRef)).data()?.treatments;
        if (treatmentData.caregivers.includes(caregiversId)) {
            const filteredTreatments = caregiverTreatments.filter(
                (tr: string) => tr !== treatmentData.id
            );
            updateDoc(caregiverDocRef, {
                treatments: filteredTreatments,
            });
            const filteredCaregivers = treatmentData.caregivers.filter(
                (treatment) => treatment !== caregiversId
            );
            setTreatmentData({ ...treatmentData, caregivers: filteredCaregivers });
            return;
        }
        updateDoc(caregiverDocRef, {
            treatments: [...caregiverTreatments, treatmentData.id],
        });
        setTreatmentData({
            ...treatmentData,
            caregivers: [...treatmentData.caregivers, caregiversId],
        });
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTreatmentData({ ...treatmentData, [e.target.name]: e.target.value });
    };

    const handleTreatmentsForm = (e: FormEvent<HTMLFormElement>) => {
        if (!clinic) return;
        e.preventDefault();
        const db = getFirestore();
        if (treatmentData.id) {
            const treatmentRef = doc(
                collection(db, 'clinicSettings', clinic, 'treatments'),
                treatmentData.id
            );
            setDoc(treatmentRef, treatmentData, { merge: true })
                .then(() => setStatus('Uppdaterad'))
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                    setError(true);
                    setStatus('Uppdatering misslyckades');
                });
            return;
        }

        const treatmentRef = doc(collection(db, 'clinicSettings', clinic, 'treatments'));
        setTreatmentData({ ...treatmentData, id: treatmentRef.id });
        setDoc(treatmentRef, {
            ...treatmentData,
        })
            .then(() => setStatus('Skickad'))
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                setError(true);
                setStatus('Ett fel uppstod');
            });
    };
    return (
        <div className="m-5">
            <div className="flex flex-col gap-y-3 min-w-full">
                <label
                    htmlFor="my-modal"
                    className="btn gap-2 w-[180px] btn-secondary bg-hs-blue mb-5 hover:bg-secondary"
                >
                    <PlusIcon className="w-6 h-6" />
                    treatment
                </label>
                {treatments &&
                    treatments.map((treatment) => {
                        return (
                            <div
                                key={treatment.id}
                                className="flex justify-between  items-center px-5 bg-white w-full h-16 rounded-md "
                            >
                                <div key={treatment.id} className="flex justify-start gap-x-1 ">
                                    <p>{treatment.name}</p>
                                </div>
                                <div className="flex gap-x-2">
                                    {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                    </svg> */}
                                    <label
                                        htmlFor="my-modal"
                                        className="text-secondary cursor-pointer"
                                        onClick={() => setTreatmentData(treatment)}
                                    >
                                        Edit
                                    </label>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <input type="checkbox" id="my-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box">
                    <h3 className="font-semibold text-lg mb-3">Add treatment</h3>
                    <form className="flex flex-col gap-y-3" onSubmit={handleTreatmentsForm}>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Name</span>
                            </label>
                            <input
                                placeholder="Dermapen - Ansikte"
                                className="input input-bordered w-full"
                                name="name"
                                value={treatmentData.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Duration (minutes)</span>
                            </label>
                            <input
                                type="text"
                                placeholder="60"
                                className="input input-bordered w-full"
                                name="duration"
                                value={treatmentData.duration}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Price (SEK)</span>
                            </label>
                            <input
                                placeholder="600"
                                className="input input-bordered w-full"
                                name="price"
                                value={treatmentData.price}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <textarea
                            placeholder="Description"
                            className="textarea textarea-bordered"
                            name="description"
                            rows={6}
                            value={treatmentData.description}
                            onChange={(e) =>
                                setTreatmentData({ ...treatmentData, description: e.target.value })
                            }
                        />
                        <p>Select doctors for this treatment</p>

                        <div className="grid grid-cols-2 ">
                            {caregivers &&
                                caregivers.map((caregiver) => {
                                    return (
                                        <label
                                            key={caregiver.id}
                                            className="label cursor-pointer flex justify-start gap-x-2"
                                        >
                                            <input
                                                type="checkbox"
                                                className="checkbox checkbox-secondary"
                                                checked={treatmentData.caregivers.includes(
                                                    caregiver.id
                                                )}
                                                onChange={() => selectCaregiver(caregiver.id)}
                                            />
                                            <span className="label-text">
                                                {`${caregiver.firstName} ${caregiver.lastName}`}
                                            </span>
                                        </label>
                                    );
                                })}
                        </div>
                        <div className="flex justify-between w-full items-end">
                            <div>
                                <p className={`${error ? 'text-error' : 'text-success'}`}>
                                    {status}
                                </p>
                            </div>
                            <div className="modal-action">
                                {treatmentData.caregivers.length < 1 ? (
                                    <button className="btn" disabled>
                                        Send
                                    </button>
                                ) : (
                                    <button className="btn btn-secondary" type="submit">
                                        Send
                                    </button>
                                )}
                                <label
                                    htmlFor="my-modal"
                                    className="btn btn-error text-white"
                                    onClick={() => {
                                        setTreatmentData({
                                            name: '',
                                            id: '',
                                            description: '',
                                            duration: 0,
                                            price: '',
                                            caregivers: [],
                                        });
                                        setStatus('');
                                    }}
                                >
                                    Close
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default Treatments;
